import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SignUpPopup from '../components/SignUpPopup';
import ThankYouPopup from '../components/ThankYouPopup';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import './All-games.css';

function AllGames({countryData}) {

  let navigate = useNavigate();
  const location = useLocation();

  const [showRegistrationBtn, setShowRegistrationBtn] = useState(true);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [showSClickId, setShowSClickId] = useState(false);
  const [sClickIdFrameSrc, setSClickIdFrameSrc] = useState('');

  const search = useLocation().search;
  const sClickId = new URLSearchParams(search).get('_sclickid');

  const b64_to_utf8 = (base64String) => {
    let atob = require('atob');
    try {
      var bin = atob(base64String);
      return bin;
    } catch(e) {
      return '';
    }
  }

  useEffect(() => {
    isLooged();

    const renderSClickFrame = () => {
      let frameSrc = b64_to_utf8(sClickId);
      setShowSClickId(true);
      setSClickIdFrameSrc(frameSrc);
    }

    if(sClickId && sClickId.length > 1) {
      renderSClickFrame();
    }

  }, [sClickId]);


  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
        .then((response) => response.json())
        .then((result) => {
          if(result.is_auth === true) {
            setShowRegistrationBtn(false);
            // setCanStartPlay(true)
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }
  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  }

  const userRegistered = () => {
    console.log('success');
    closeRegisterPopup();
    setShowThanksPopup(true);
  }

  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/all-games/";
  }

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    console.log(location.pathname);
  }

  return(
      <div className="lp-page-cover">
        <Helmet>
          <title>Jackpot Squared Game  - All Games</title>
          <link rel="canonical" href="https://www.jackpotsquared.com/all-games/" />
        </Helmet>
        <React.Fragment>
          { showRegisterPopup ? (
              <SignUpPopup closeRegisterPopup={ closeRegisterPopup } userLogged={ userLogged } userRegistered={ userRegistered } countryData={ countryData } />
          ) : ( null ) }
        </React.Fragment>

        <React.Fragment>
          { showThanksPopup ? (
              <ThankYouPopup closeThanksPopup={ closeThanksPopup }  />
          ) : ( null ) }
        </React.Fragment>

        <div className="all-games-content">
          <h1>Hot Social Casino Games</h1>
        </div>

        <span id="sport"></span>

        <div className="categories-menu-cover">
          <nav className="categories-menu">
            <ul>
              <li>
                <AnchorLink href="#sport">
                  <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
                  <span>Sport</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="#black-jack">
                  <img src="/assets/img/02-all-games-icon.webp" alt="icon"/>
                  <span>black jack</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="#roulette">
                  <img src="/assets/img/03-all-games-icon.webp" alt="icon"/>
                  <span>roulette</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="#slots">
                  <img src="/assets/img/04-all-games-icon.webp" alt="icon"/>
                  <span>slots</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="#poker">
                  <img src="/assets/img/05-all-games-icon.webp" alt="icon"/>
                  <span>poker</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink href="#jackpot">
                  <img src="/assets/img/06-all-games-icon.webp" alt="icon"/>
                  <span>jackpot</span>
                </AnchorLink>
              </li>
            </ul>
          </nav>
        </div>

        <div className="all-games-content">
          <div className="lp-games-cover" >
            <div className="games-label">
              <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
              <span>Sport</span>
            </div>
            <div className="lp-games">
              <div className="all-game-icons-cover"  onClick={() => {navigate(`/games/football-champions-cup/`)} }>
                <img src="/assets/img/all-games-14.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/football-champions-cup/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/basketball/`)} }>
                <img src="/assets/img/all-games-15.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/basketball/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/hugo-goal/`)} }>
                <img src="/assets/img/all-games-16.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/hugo-goal/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/penalty-series/`)} }>
                <img src="/assets/img/all-games-17.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/penalty-series/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/football/`)} }>
                <img src="/assets/img/all-games-18.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/football/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/hockey-league-wild-match/`)} }>
                <img src="/assets/img/all-games-19.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/hockey-league-wild-match/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/golden-goal/`)} }>
                <img src="/assets/img/all-games-20.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/golden-goal/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/gold-trophy-2/`)} }>
                <img src="/assets/img/all-games-21.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/gold-trophy-2/">Play now</Link>
                </React.Fragment>
                <span id="black-jack"></span>
              </div>
            </div>
          </div>

          <div className="lp-games-cover">
            <div className="games-label">
              <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
              <span>black jack</span>
            </div>
            <div className="lp-games">
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/blackjack/`)} }>
                <img src="/assets/img/all-games-22.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/blackjack/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/blackjack-lucky-seven/`)} }>
                <img src="/assets/img/all-games-23.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/blackjack-lucky-seven/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/blackjack-double-exposure/`)} }>
                <img src="/assets/img/all-games-24.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/blackjack-double-exposure/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/blackjack-vip/`)} }>
                <img src="/assets/img/all-games-25.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/blackjack-vip/">Play now</Link>
                </React.Fragment>
                <span id="roulette"></span>
              </div>
            </div>
          </div>


          <div className="lp-games-cover">
            <div className="games-label">
              <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
              <span>roulette</span>
            </div>
            <div className="lp-games">
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/european-roullette/`)} }>
                <img src="/assets/img/all-games-26.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/european-roullette/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/bonanza-wheel/`)} }>
                <img src="/assets/img/all-games-27.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/bonanza-wheel/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/wheel-of-time/`)} }>
                <img src="/assets/img/all-games-28.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/wheel-of-time/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/american-roulette-3d/`)} }>
                <img src="/assets/img/all-games-29.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/american-roulette-3d/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/european-roullette-high-stakes/`)} }>
                <img src="/assets/img/all-games-30.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/european-roullette-high-stakes/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/super-wheel/`)} }>
                <img src="/assets/img/all-games-31.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/super-wheel/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/magic-wheel/`)} }>
                <img src="/assets/img/all-games-32.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/magic-wheel/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/european-roulette-pro/`)} }>
                <img src="/assets/img/all-games-33.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/european-roulette-pro/">Play now</Link>
                </React.Fragment>
                <span id="slots"></span>
              </div>
            </div>
          </div>


          <div className="lp-games-cover">
            <div className="games-label">
              <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
              <span>slots</span>
            </div>
            <div className="lp-games">
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/fire-joker/`)} }>
                <img src="/assets/img/all-games-34.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/fire-joker/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/jade-magician/`)} }>
                <img src="/assets/img/all-games-35.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/jade-magician/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/diamond-strike/`)} }>
                <img src="/assets/img/all-games-36.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/diamond-strike/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/sea-hunter/`)} }>
                <img src="/assets/img/all-games-37.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/sea-hunter/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/deuces-wild/`)} }>
                <img src="/assets/img/all-games-38.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/deuces-wild/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/gemix/`)} }>
                <img src="/assets/img/all-games-39.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/gemix/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/grim-muerto/`)} }>
                <img src="/assets/img/all-games-40.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/grim-muerto/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/jolly-roger/`)} }>
                <img src="/assets/img/all-games-41.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/jolly-roger/">Play now</Link>
                </React.Fragment>
                <span id="poker"></span>
              </div>
            </div>
          </div>


          <div className="lp-games-cover">
            <div className="games-label">
              <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
              <span>poker</span>
            </div>
            <div className="lp-games">
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/texas-holdem/`)} }>
                <img src="/assets/img/all-games-42.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/texas-holdem/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/baccarat/`)} }>
                <img src="/assets/img/all-games-43.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/baccarat/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/texas-holdem-bonus/`)} }>
                <img src="/assets/img/all-games-44.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/texas-holdem-bonus/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/oasis-poker/`)} }>
                <img src="/assets/img/all-games-45.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/oasis-poker/">Play now</Link>
                </React.Fragment>
                <span id="jackpot"></span>
              </div>
            </div>
          </div>


          <div className="lp-games-cover">
            <div className="games-label">
              <img src="/assets/img/01-all-games-icon.webp" alt="icon"/>
              <span>jackpot</span>
            </div>
            <div className="lp-games">
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/big-win-cat/`)} }>
                <img src="/assets/img/all-games-46.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/big-win-cat/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/super-flip/`)} }>
                <img src="/assets/img/all-games-47.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/super-flip/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/guns-linger-reloaded/`)} }>
                <img src="/assets/img/all-games-48.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/guns-linger-reloaded/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/chilli-bang/`)} }>
                <img src="/assets/img/all-games-49.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/chilli-bang/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/atlantis/`)} }>
                <img src="/assets/img/all-games-50.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/atlantis/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/vegas-nights/`)} }>
                <img src="/assets/img/all-games-51.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/vegas-nights/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/neonstaxx/`)} }>
                <img src="/assets/img/all-games-52.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/neonstaxx/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/jackpot-fortunes/`)} }>
                <img src="/assets/img/all-games-53.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/jackpot-fortunes/">Play now</Link>
                </React.Fragment>
              </div><div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/shadow-of-luxor/`)} }>
              <img src="/assets/img/all-games-54.webp" alt="game"/>
              <React.Fragment>

                <Link className="play-game-btn" to="/games/shadow-of-luxor/">Play now</Link>
              </React.Fragment>
            </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/star-joker/`)} }>
                <img src="/assets/img/all-games-55.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/star-joker/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/planet-fortune/`)} }>
                <img src="/assets/img/all-games-56.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/planet-fortune/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="all-game-icons-cover"  onClick={ () => {navigate(`/games/royal-cash/`)} }>
                <img src="/assets/img/all-games-57.webp" alt="game"/>
                <React.Fragment>

                  <Link className="play-game-btn" to="/games/royal-cash/">Play now</Link>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>

        <React.Fragment>
          { showSClickId ? (
              <iframe title="sclickid" className="sclick-id-style" src={ sClickIdFrameSrc }></iframe>
          ) : ( null ) }
        </React.Fragment>
      </div>
  )
}

export default AllGames;
