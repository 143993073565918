import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
// import useAsyncEffect from 'use-async-effect';

import OpenPageOnTop from './OpenPageOnTop';
import Header from './Header';
import Footer from './Footer';
import Home from '../pages/Home';
import Play from '../pages/Play';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';
import Cookies from '../pages/Cookies';
import Disclaimer from '../pages/Disclaimer';
import Lp from '../pages/Lp';
import Top10 from '../pages/Top10';
import AllGames from '../pages/All-games';
import PageNotFound from '../pages/PageNotFound';

import './App.css';

function App() {
  const [ cookies, setCookie ] = useCookies(['is18']);
  const [ countryData, setCountryData ] = useState('UA');

  useEffect(() => {

    fetch(`https://ipapi.co/json/?key=1W62DydpgUZwtxR5l6BW8UPckBu2NLTTJLzOiuqtkwHgz4tNXz`)
      .then((response) => response.json())
      .then((response) => {
        let data = {
          code: response.country_code,
          country: response.country_name,
        }
        setCountryData(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  if(cookies.is18 === undefined) {
    setCookie('is18', 'false', { path: '/' });
  }

  return (
    <Router>
      <div className="App">
        <Header countryData={ countryData } />
        <OpenPageOnTop />
          <Routes>
            <Route path="/" element={ <Home is18={ cookies.is18 } countryData={ countryData }/> }  />
            <Route path="/games/:gameUrl" element={ <Play is18={ cookies.is18 } countryData={ countryData } /> } />
            <Route path="/about/" element={ <About /> } />
            <Route path="/contact/" element={ <Contact /> } />
            <Route path="/privacy/" element={ <Privacy /> } />
            <Route path="/terms/" element={ <Terms /> } />
            <Route path="/disclaimer/" element={ <Disclaimer /> } />
            <Route path="/cookies/" element={ <Cookies /> } />
            <Route path="/top10/" element={ <Top10 countryData={ countryData } /> } />
            <Route path="/usa/" element={ <Lp is18={ cookies.is18 } countryData={ countryData } /> } />
            <Route path="/all-games/" element={ <AllGames is18={ cookies.is18 } countryData={ countryData } /> } />
            <Route path="/home/" element={ <Home countryData={ countryData } /> } />
            {/*<Route path="/page404" element={ <PageNotFound /> } />*/}
            <Route path="*" element={ <PageNotFound /> } />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
