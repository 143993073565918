import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import './Disclaimer.css';

function Disclaimer() {

  const [contentHeight, setContentHeight ] = useState();

  useEffect(() => {
    document.title = 'Jackpot Squared - Disclaimer';

    setContentHeight(document.body.clientHeight);
  }, [setContentHeight]);

  return(
    <>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/disclaimer/" />
      </Helmet>
      <div className="text-page-body" style={ {backgroundImage: `url('/assets/img/back2-02.jpg')`, minHeight: `${ contentHeight }px`} }>
        <div className="content-cover">
          <h1>Disclaimer</h1>
          <p>Jackpot Squared does not provide any gambling services. The games on this website are designed for leisure and instruction only, absolutely free, and without any possibility to win real money prizes. Be aware that however good you will be at playing in our casino it does not give you a chance to win in actual casino games. That’s why you have to be at least 21 y.o. to participate in our program.</p>
          <p>Jackpot Squared may promote and display advertising and links to gambling services and other third-party websites as an affiliate site. Jackpot Squared is not affiliated with any of the external websites linked to its pages. We are not affiliated with, nor will we be liable for, third parties linked to our website.</p>
          <p>To the best of our knowledge, the information we provide is accurate at the time of publication. We make no guarantees that it remains up-to-date all the time, but we are trying to update and change on a regular basis.</p>
          <p>Furthermore, we cannot be held responsible or legally responsible for any content or conduct that our users may encounter when using external links on our website. If you are going to visit these websites, you must read Privacy Policy and Terms and Conditions of appropriate websites.</p>
          <p>Our users need to understand that there are risks involved in gambling online. The only purpose of Jackpot Squared is entertainment only. The character of our games requires your careful attention and mindful action. You should be also aware that some players find it difficult to view gambling as merely a way to relax and only risk what they can do, as most players do. In case you feel getting dependent on the game, please consult the needed practitioner.</p>
        </div>
      </div>
    </>
  )
}

export default Disclaimer;