import React, { useEffect, useState } from 'react';
import './Cookies.css';

function Cookies() {

  const [contentHeight, setContentHeight ] = useState();

  useEffect(() => {
    document.title = 'Jackpot Squared - Page Not Found';
    
    setContentHeight(document.body.clientHeight);
  }, [setContentHeight]);

  return(
    <div>
      <div className="text-page-body" style={ {backgroundImage: `url('/assets/img/back2-02.jpg')`, minHeight: `${ contentHeight }px`} }>
        <div className="page-not-found">
          <h1>Oops.... Page not found</h1>
        </div>
      </div>
    </div>
  )
}

export default Cookies;