import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import OwlCarousel from 'react-owl-carousel2';
import SignUpPopup from '../components/SignUpPopup';
import ThankYouPopup from '../components/ThankYouPopup';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import './Home.css';
import '../components/owl-carousel.css';

function Home({countryData}) {
  
  let navigate = useNavigate();
  const location = useLocation();

  const [showRegistrationBtn, setShowRegistrationBtn] = useState(true);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);  
  // const [canStartPlay, setCanStartPlay] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [bodyHeight, setBodyHeight] = useState();


  useEffect(() => {

    document.title = 'Jackpot Squared - Home';
    document.querySelector("meta[name='description']").setAttribute('content',"Welcome to Jackpot Squared! Give some fun to your everyday life and take some time playing at our casino.");
    isLooged();

    const contentBlock = document.querySelector('#games-carousel-cover');
    setBodyHeight(contentBlock.clientHeight - 400);
    
  }, []);


  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if(result.is_auth === true) {
          setShowRegistrationBtn(false);
          // setCanStartPlay(true)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  
  // const showAgePopupHandler = (e) => {
  //   let game = e.target.id.replace('game-', '');

  //   setTheGame(game);
  //   setShowAgepopup(true);
  // }

  const openSignUpPopup = () => {
    setShowRegisterPopup(true);
  }

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  }

  const userRegistered = () => {
    console.log('success');
    closeRegisterPopup();
    setShowThanksPopup(true);
  }

  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/";
  }

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    console.log(location.pathname);
    if (location.pathname === '/usa/' || location.pathname === '/usa') {
      window.location.href = "/usa/";
    } else {
      window.location.href = "/";
    }
  }

  const owlPptions1 = {
    items: 4,
    autoplay: true,
    rewind: true,
    margin: 15,
    loop: true,
    responsive: {
      300: {
        items:1
      },
      560: {
        items:2
      },
      1000: {
        items:3
      },
      1200: {
        items:4
      },
    }
  };

  const owlPptions2 = {
    items: 3,
    autoplay: true,
    margin: 30,
    rewind: true,
    loop: true,
    autoplayTimeout: 6500,
    responsive: {
      300: {
        items:1
      },
      560: {
        items:2
      },
      800: {
        items:3
      },
      1000: {
        items:3
      },
    }
  };

  return(
    <>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/" />
      </Helmet>
      <div className="home-page-cover" style={ {backgroundImage: `url('/assets/img/main-bg.webp')`} }>

        <React.Fragment>
          { showRegisterPopup ? (
              <SignUpPopup closeRegisterPopup={ closeRegisterPopup } userLogged={ userLogged } userRegistered={ userRegistered } countryData={ countryData } />
          ) : ( null ) }
        </React.Fragment>

        <React.Fragment>
          { showThanksPopup ? (
              <ThankYouPopup closeThanksPopup={ closeThanksPopup }  />
          ) : ( null ) }
        </React.Fragment>

        <div className="first-screen-banner">
          <AnchorLink href="#games-carousel-cover" >
            <img src="/assets/img/header-back.webp" className="display-banner" alt="banner" width="1440" height="1145" />
          </AnchorLink>
          <img src="/assets/img/header-back.webp" className="mobile-banner" onClick={ () => {navigate(`/games/diamond-strike/`)} } alt="banner" width="1440" height="1145" />
        </div>

        <div id="games-carousel-cover" className="home-content" style={ {minHeight: `${ bodyHeight }px` } }>

          <div className="carousel-game-icons">
            <OwlCarousel options={ owlPptions1 } >
              <div className="carousel-game-icons-cover" onClick={ () => {navigate(`/games/diamond-strike/`)} }>
                <img width="80" height="80" src="/assets/img/game-icon-01.webp" alt="game"/>
                <div className="game-icons-cover-content">
                  <h3>Diamond Strike</h3>
                  <p>Scratchcard game</p>
                </div>
              </div>
              <div className="carousel-game-icons-cover"  onClick={ () => {navigate(`/games/fire-joker/`)} }>
                <img width="80" height="80" src="/assets/img/game-icon-02.webp" alt="game"/>
                <div className="game-icons-cover-content">
                  <h3>Fire Joker</h3>
                  <p>Slot game</p>
                </div>
              </div>
              <div className="carousel-game-icons-cover"  onClick={ () => {navigate(`/games/jade-magician/`)} }>
                <img width="80" height="80" src="/assets/img/game-icon-03.webp" alt="game"/>
                <div className="game-icons-cover-content">
                  <h3>Jade Magician</h3>
                  <p>Slot game</p>
                </div>
              </div>
              <div className="carousel-game-icons-cover"  onClick={ () => {navigate(`/games/sea-hunter/`)} }>
                <img width="80" height="80" src="/assets/img/game-icon-04.webp" alt="game"/>
                <div className="game-icons-cover-content">
                  <h3>Sea Hunter</h3>
                  <p>Slot game</p>
                </div>
              </div>
              <div className="carousel-game-icons-cover"  onClick={ () => {navigate(`/games/deuces-wild/`)} }>
                <img width="80" height="80" src="/assets/img/game-icon-05.webp" alt="game"/>
                <div className="game-icons-cover-content">
                  <h3>Deuces Wild MH</h3>
                  <p>Card game</p>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <div className="carousel-game-image">
            <OwlCarousel options={ owlPptions2 } >
              <div className="carousel-game-image-cover">
                <img width="360" height="240" src="/assets/img/game-img-01.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/diamond-strike/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="carousel-game-image-cover">
                <img width="360" height="240" src="/assets/img/game-img-02.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/fire-joker/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="carousel-game-image-cover">
                <img width="360" height="240" src="/assets/img/game-img-03.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/jade-magician/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="carousel-game-image-cover">
                <img width="360" height="240" src="/assets/img/game-img-04.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/sea-hunter/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="carousel-game-image-cover">
                <img width="360" height="240" src="/assets/img/game-img-05.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/deuces-wild/">Play now</Link>
                </React.Fragment>
              </div>
            </OwlCarousel>
          </div>

          <div className="about-block">
            <div className="about-block-image">
              <img src="/assets/img/about.webp" alt="" width="423" height="477"/>
            </div>
            <div className="about-block-text">
              <h2>About</h2>
              <p>Welcome to Jackpot Squared! Give some fun to your everyday life and take some time playing at our casino. Yes, it is not a real casino. All the games of Jackpot Squared are <strong>entirely FREE</strong>. That’s why you can be absolutely sure about your pocket.</p>
              <p>You may ask: why are they doing it? The question is quite simple. We want you to feel the eagerness of hitting a jackpot. Our team is fond of games and loves our players! Thinking of you, we have created an exquisite online casino, where you will find lots of the most interesting games.</p>
              <p>So stop hesitating and begin to play now!</p>
              <React.Fragment>
                { showRegistrationBtn ? (
                    <button className="signup-about-btn" onClick={ openSignUpPopup }>Sign Up</button>
                ) : ( null ) }
              </React.Fragment>
            </div>
          </div>
          <div className="game-bottom">
            <div className="game-bottom-up">
              <h4>Diamond Strike</h4>
              <img width="256" height="200" src="/assets/img/game-1.webp" alt="game"/>
              <React.Fragment>
                <Link className="play-game-btn" to="/games/diamond-strike/">Play now</Link>
              </React.Fragment>
            </div>
            <div className="game-bottom-down">
              <img width="256" height="200" src="/assets/img/game-2.webp" alt="game"/>
              <h4>Fire Joker</h4>
              <React.Fragment>
                <Link className="play-game-btn" to="/games/fire-joker/">Play now</Link>
              </React.Fragment>
            </div>
            <div className="game-bottom-up">
              <h4>Jade Magician</h4>
              <img width="256" height="200" src="/assets/img/game-3.webp" alt="game"/>
              <React.Fragment>
                <Link className="play-game-btn" to="/games/jade-magician/">Play now</Link>
              </React.Fragment>
            </div>
            <div className="game-bottom-down">
              <img width="256" height="200" src="/assets/img/game-4.webp" alt="game"/>
              <h4>Sea Hunter</h4>
              <React.Fragment>
                <Link className="play-game-btn" to="/games/sea-hunter/">Play now</Link>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;
