import React, { useEffect, useState } from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';

import './Play.css';
import {Helmet} from "react-helmet";
import {gamesUrlList} from "../data/gamesArr";
import PageNotFound from '../pages/PageNotFound';

function Play({ is18 }) {

  let navigate = useNavigate();
  let { gameUrl } = useParams();
  const [url, setUrl] = useState('');

  function titleCase(str) {
    const splitStr = str.toLowerCase().split('-');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  useEffect(() => {
    document.title = `Jackpot Squared - ${titleCase(gameUrl)}`;

    if (gameUrl) {
      let filteredGame = gamesUrlList.filter(game => game.name === gameUrl);
      if(filteredGame.length){
        setUrl(filteredGame[0].link);
      }else{
        window.location.href = `/`;
      }
    } else {
      window.location.href = `/`;
    }

  }, [setUrl, gameUrl, is18, navigate]);

  const clearConsole = () => {
    console.clear();
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/games/" />
      </Helmet>
      <div className="text-page-body" style={{ backgroundImage: `url('/assets/img/back2-02.jpg')` }}>
        <div className="content-cover">
          <div className="game-back-link" onClick={() => { clearConsole(); goBack(); }}><i className="fas fa-arrow-left"></i>Back</div>
          <div className="game-cover">
            <iframe title="game" src={url} frameBorder="0"></iframe>
          </div>
        </div>
      </div >
    </>
  );
}

export default Play;
