export const gamesUrlList = [
    //0
    {name: 'fire-joker', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=firejoker&gameId=307&lang=en&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //1
    {name: 'matsuri', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=matsuri&gameId=320&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //2
    {name: 'spin-party', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=spinparty&gameId=292&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //3
    {name: 'hot-spin-delux', link: 'https://game-launcher-lux.isoftbet.com/51/907033?background=0&lang=en&cur=EUR&mode=0'},
    //4
    {name: 'cats-and-cash', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=catsandcash&gameId=193&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //5
    {name: 'gemix', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=gemix&gameId=286&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //6
    {name: 'football-champions-cup', link: 'https://netent-static.casinomodule.com/games/football_mobile_html/game/football_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=football_not_mobile&showHomeButton=false&gameLocation=games%2Ffootball_mobile_html%2F&preBuiltGameRulesSupported=true&server=https%3A%2F%2Fnetent-game.casinomodule.com%2F&lang=en&sessId=DEMO-4268833735-USD&operatorId=netent'},
    //7
    {name: 'basketball', link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/basketball'},
    //8
    {name: 'hugo-goal', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=hugogoal&gameId=354&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=%22'},
    //9
    {name: 'penalty-series', link: 'https://demo.evoplay.games/demo/instant/html5/evoplay/penaltyseries'},
    //10
    {name: 'football', link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/football'},
    //11
    {name: 'hockey-league-wild-match', link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?gameSymbol=vs20hockey&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobby_url=https%3A%2F%2Fwww.pragmaticplay.com%2Fen%2F&lang=EN&cur=USD'},
    //12
    {name: 'golden-goal', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=goldengoal&gameId=108&lang=en_GB&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //13
    {name: 'gold-trophy-2', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=goldtrophy2&gameId=246&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //14
    {name: 'blackjack', link: 'https://rgstorgs.stage.pariplaygames.com/pariplay?mode=fun&currency=USD&lang=EN&gameCode=PP_HTML5_Blackjack&homeUrl=&debugstate=&operatorId=PP'},
    //15
    {name: 'blackjack-lucky-seven', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/blackjack'},
    //16
    {name: 'blackjack-double-exposure', link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=doubleexposureblackjackmh&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True'},
    //17
    {name: 'blackjack-vip', link: 'https://showcase.darwininteractive.com/darwin/demo/?currency=USD&language=en-us&device=desktop&token=60164937047934.52&gameID=silvercurlewspider'},
    //18
    {name: 'european-roullette', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/europeanroulette'},
    //19
    {name: 'bonanza-wheel', link: 'https://demo.evoplay.games/demo/instant/html5/evoplay/bonanzawheel'},
    //20
    {name: 'wheel-of-time', link: 'https://demo.evoplay.games/demo/instant/html5/evoplay/wheeloftime'},
    //21
    {name: 'american-roulette-3d', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/americanroulette'},
    //22
    {name: 'european-roullette-high-stakes', link: 'https://d21j22mhfwmuah.cloudfront.net/0Debug/PP_HTML5_EuropeanRoulette/index.html?gameCode=PP_HTML5_EuropeanRouletteHighStakes&token=DEMO_PP_bc0d7697-9667-4424-80dd-a85fed655822&homeUrl=&rgsUrl=https://rgstorgs.stage.pariplaygames.com&lang=EN&DebugMode=False&currencyCode=EUR&disableRotation=False&ExtraData=networkId%3dPP&HideCoins=False&CoinsDefault=True'},
    //23
    {name: 'super-wheel', link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=superwheel&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True'},
    //24
    {name: 'magic-wheel', link: 'https://demo.evoplay.games/demo/instant/html5/evoplay/magicwheel'},
    //25
    {name: 'european-roulette-pro', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=europeanroulette&gameId=31&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //26
    {name: 'fire-joker', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=firejoker&gameId=307&lang=en&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //27
    {name: 'jade-magician', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=jademagician&gameId=326&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //28
    {name: 'diamond-strike', link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=EUR&gameSymbol=scdiamond&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&lobbyURL=https%3A%2F%2Fwww.pragmaticplay.com'},
    //29
    {name: 'sea-hunter', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=seahunter&gameId=331&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //30
    {name: 'deuces-wild', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=deuceswild&gameId=270&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //31
    {name: 'gemix', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=gemix&gameId=286&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //32
    {name: 'grim-muerto', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=grimmuerto&gameId=303&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //33
    {name: 'jolly-roger', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=jollyroger&gameId=40&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //34
    {name: 'texas-holdem', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/texasholdempoker3d'},
    //35
    {name: 'baccarat', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/baccarat'},
    //36
    {name: 'texas-holdem-bonus', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/texasholdembonus'},
    //37
    {name: 'oasis-poker', link: 'https://demo.evoplay.games/demo/table/html5/evoplay/oasispokerclassic'},
    //38
    {name: 'big-win-cat', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bigwincat&gameId=342&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //39
    {name: 'super-flip', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=superflip&gameId=295&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
    //40
    {name: 'guns-linger-reloaded', link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=gunslingerreloaded&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True'},
    //41
    {name: 'chilli-bang', link: 'https://game-launcher-lux.isoftbet.com/255/904628?lang=en&cur=EUR&mode=0'},
    //42
    {name: 'atlantis', link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/atlantis'},
    //43
    {name: 'vegas-nights', link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/vegasnights'},
    //44
    {name: 'neonstaxx', link: 'https://netent-static.casinomodule.com/games/neonstaxx_mobile_html/game/neonstaxx_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=neonstaxx_not_mobile&gameLocation=games%2Fneonstaxx_mobile_html%2F&preBuiltGameRulesSupported=true&server=https%3A%2F%2Fnetent-game.casinomodule.com%2F&lang=en&sessId=DEMO-8999160028-USD&operatorId=netent'},
    //45
    {name: 'jackpot-fortunes', link: 'https://rgstorgs.stage.pariplaygames.com/pariplay?mode=fun&currency=USD&lang=EN&gameCode=PP_HTML5_JackpotFortunes96&homeUrl=&debugstate=&operatorId=PP'},
    //46
    {name: 'shadow-of-luxor', link: 'https://demo.evoplay.games/demo/fullstate/html5/evoplay/shadowofluxorjackpot'},
    //47
    {name: 'star-joker', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=starjoker&gameId=340&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
    //48
    {name: 'planet-fortune', link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=planetfortune&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True'},
    //49
    {name: 'royal-cash', link: 'https://game-launcher-lux.isoftbet.com/255/904612?lang=en&cur=EUR&mode=0'}
];