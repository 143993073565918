import React from 'react';
import './ThankYouPopup.css';

function ThankYouPopup({ closeThanksPopup }) {

  return(
    <div className="thank-you-popup" onClick={() => closeThanksPopup()}>
      <div className="thank-you-overlay"></div>
      <div className="thank-you-body" style={ { backgroundImage: `url('/assets/img/thank-you-popup.webp')` } }>
      <button className="close-popup-btn"><i className="fas fa-times"></i></button>
      <h2>THANK YOU</h2>
      <h3> FOR signing up!</h3>
      <p>Check your email & SMS for your secret welcome bonus!</p>
      </div>
    </div>
  )
}

export default ThankYouPopup;