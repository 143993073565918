import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import './Cookies.css';

function Cookies() {

  const [contentHeight, setContentHeight ] = useState();

  useEffect(() => {
    document.title = 'Jackpot Squared - Cookies Policy';
    
    setContentHeight(document.body.clientHeight);
  }, [setContentHeight]);

  return(
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/cookies/" />
      </Helmet>
      <div className="text-page-body" style={ {backgroundImage: `url('/assets/img/back2-02.jpg')`, minHeight: `${ contentHeight }px`} }>

      <img src="/assets/img/jack-logo.png" className="jack-logo" alt=""/>
        <div className="content-cover">
          <h1>Cookies Policy</h1>
          <p>When you visit our Website, we automatically collect specific categories of personal data using cookies and similar technologies. When using the Website, a cookie will be saved on your computer. In general, cookies and similar technologies work by assigning a unique number to your browser or device that is irrelevant outside Our Site.</p> 
          <p>These technologies enable personalization of the use of the Website, including language and currency preferences, and help provide content tailored to your interests. In addition, after entering your email address and password during a session on the Website, we save this information so that you do not have to enter it repeatedly during this session. If you consent, it can be extended by information using cookies or other equivalent technology; you can use the settings in your browser or mobile device. We strive to provide you with the ability to manage your privacy and data sharing.</p>

          <Link className="game-back-link" to='/'><i className="fas fa-arrow-left"></i>Back</Link>
        </div>
      </div>
    </div>
  )
}

export default Cookies;