import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import './Footer.css';

function Footer() {
  return(
    <footer className="footer">
      <div className="footer-cover">
        <Link to="/">
          <img src="/assets/img/jack-logo.webp" className="footer-logo" alt=""/>
        </Link>
        <h2>Disclaimer</h2>
        <p>This casino offers FREE games only with NO real money prizes. But this is a gaming activity and you have to be over 21 y.o. before playing in Jackpot Squared.</p>
        <ul className="footer-nav">
          <li>
            <NavLink to="/">Home</NavLink><span>|</span>
          </li>
          <li>
            <NavLink to="/about/">About</NavLink><span>|</span>
          </li>
          <li>
            <NavLink to="/disclaimer/">Disclaimer</NavLink><span>|</span>
          </li>
          <li>
            <NavLink to="/privacy/">Privacy Policy</NavLink><span>|</span>
          </li>
          <li>
            <NavLink to="/terms/">Terms & Conditions</NavLink>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer;