import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import './Top10.css';
import { Link, useLocation } from 'react-router-dom';
import SignUpPopup from "../components/SignUpPopup";
import ThankYouPopup from "../components/ThankYouPopup";

function Top10({countryData}) {
    const location = useLocation();

    // const [showRegistrationBtn, setShowRegistrationBtn] = useState(true);
    const [showRegisterPopup, setShowRegisterPopup] = useState(false);
    const [showThanksPopup, setShowThanksPopup] = useState(false);
    const [bodyHeight, setBodyHeight] = useState();
    const [gamesArr, setGamesArr] = useState([]);

    useEffect(() => {
        getTop10Array();
        const contentBlock = document.querySelector('.text-page-top10');
        setBodyHeight(contentBlock.clientHeight - 400);
    }, []);


    const getTop10Array = async () => {
        await fetch('/api/top10.json')
            .then((response) => response.json())
            .then((result) => {
                const games = Object.entries(result);
                setGamesArr(games);
                return games;
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const closeRegisterPopup = () => {
        setShowRegisterPopup(false);
    }

    const userRegistered = () => {
        console.log('success');
        closeRegisterPopup();
        setShowThanksPopup(true);
    }

    const userLogged = () => {
        closeRegisterPopup();
        window.location.href = "/top10/";
    }

    const closeThanksPopup = () => {
        setShowThanksPopup(false);
        console.log(location.pathname);
    }

    function formatedGameName(str){
        return str.trim().toLowerCase().replace(/\s/g, "-").replace("’", "");
    }

    return(
        <div className="text-page-top10" style={ {minHeight: `${ bodyHeight }px` } }>
            <Helmet>
                <title>Jackpot Squared Game  - Top 10 Games</title>
                <link rel="canonical" href="https://www.jackpotsquared.com/top10/" />
            </Helmet>
            <React.Fragment>
                { showRegisterPopup ? (
                    <SignUpPopup closeRegisterPopup={ closeRegisterPopup } userLogged={ userLogged } userRegistered={ userRegistered } countryData={ countryData } />
                ) : ( null ) }
            </React.Fragment>

            <React.Fragment>
                { showThanksPopup ? (
                    <ThankYouPopup closeThanksPopup={ closeThanksPopup }  />
                ) : ( null ) }
            </React.Fragment>
            <div className="content-cover-top10">
                <h2>TOP 10 SOCIAL CASINO GAMES</h2>
            </div>
            <div className="top10-games-wrap">
                    { !gamesArr.length ? <h3>Sorry, something went wrong. Please refresh the page</h3> :
                        gamesArr.map((item) =>
                            <div className="game-item" key={Math.random()}>
                                {item[1].label ? <div className="label">{item[1].label}</div> : ""}
                                <div className="game-item__left-block">
                                    <img src={`/assets/top10-img/${item[1].image}.png`} className="game-img" alt="Game Image"/>
                                    <div className="rankings">
                                        <div className="votes-block">
                                            <div className="stars-container">
                                                {[...Array(5).keys()].map((num) =>
                                                    <img key={num} src="/assets/top10-img/star.png" className="star-img" alt="Star Icon"/>
                                                )}
                                            </div>
                                            <p>Votes ({item[1].votes})</p>
                                        </div>
                                        <div className="rating-block">
                                            <p>Score</p>
                                            <p className="score-number">{item[1].scoreNumber}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="game-item__right-block">
                                    <p className="game-name">{item[1].gameName}</p>
                                    <p className="game-description">{item[1].description}</p>
                                    <Link className="top10-play-btn" to={`/games/${formatedGameName(item[1].gameName)}/`}>Play now</Link>
                                </div>
                            </div>
                    )}
            </div>
        </div>
    )
}

export default Top10;
