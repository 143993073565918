import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import SignUpPopup from '../components/SignUpPopup';
import ThankYouPopup from '../components/ThankYouPopup';
import { Parallax } from 'react-parallax';

import './Lp.css';
import '../components/owl-carousel.css';
import {Helmet} from "react-helmet";

function Lp({countryData}) {
  
  let navigate = useNavigate();
  const location = useLocation();

  // const [theGame, setTheGame] = useState();
  const [showRegistrationBtn, setShowRegistrationBtn] = useState(true);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);  
  // const [canStartPlay, setCanStartPlay] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [showSClickId, setShowSClickId] = useState(false);
  const [sClickIdFrameSrc, setSClickIdFrameSrc] = useState('');

  const search = useLocation().search;
  const sClickId = new URLSearchParams(search).get('_sclickid');
  
  const b64_to_utf8 = (base64String) => {
    let atob = require('atob');
    try {
      var bin = atob(base64String);
      return bin;
    } catch(e) {
      return '';
    }
  }

  useEffect(() => {
    document.title = 'Jackpot Squared';
    document.querySelector("meta[name='description']").setAttribute('content',"Become a part of an online gaming community to play the best social casino games for free!");
    
    isLooged();

    const renderSClickFrame = () => {
      let frameSrc = b64_to_utf8(sClickId);
      setShowSClickId(true);
      setSClickIdFrameSrc(frameSrc);
    }
    
    if(sClickId && sClickId.length > 1) {
      renderSClickFrame();
    }
  
  }, [ sClickId]);


  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if(result.is_auth === true) {
          setShowRegistrationBtn(false);
          // setCanStartPlay(true)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const openSignUpPopup = () => {
    setShowRegisterPopup(true);
  }

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  }

  const userRegistered = () => {
    console.log('success');
    closeRegisterPopup();
    setShowThanksPopup(true);
  }

  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/usa/";
  }

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    console.log(location.pathname);
    if (location.pathname === '/usa/' || location.pathname === '/usa') {
      window.location.href = "/usa/";
    } else {
      window.location.href = "/";
    }
  }

  const owlPptions = {
    items: 4,
    autoplay: true,
    margin: 30,
    rewind: true,
    loop: true,
    autoplayTimeout: 6500,
    responsive: {
      300: {
        items:1
      },
      560: {
        items:2
      },
      800: {
        items:4
      },
      1000: {
        items:4
      },
    }
  };

  return(
    <>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/usa/" />
      </Helmet>
      <div className="lp-page-cover">

        <React.Fragment>
          { showRegisterPopup ? (
              <SignUpPopup closeRegisterPopup={ closeRegisterPopup } userLogged={ userLogged } userRegistered={ userRegistered } countryData={ countryData } />
          ) : ( null ) }
        </React.Fragment>

        <React.Fragment>
          { showThanksPopup ? (
              <ThankYouPopup closeThanksPopup={ closeThanksPopup }  />
          ) : ( null ) }
        </React.Fragment>

        <div className="lp-page-content">
          <h1>GET READY TO HAVE FUN! THE BEST GAMES ARE WAITING FOR YOU!</h1>

          <div className="steps-cover">
            <div className="lp-step">
              <img src="/assets/img/step-arrow-right.webp" alt=""/>
              <h3 className="step-number">1</h3>
              <h2>REGISTER</h2>
              <p>The best social casino games for free are just a few clicks away. Sign up to get started!</p>
            </div>
            <div className="lp-step">
              <img src="/assets/img/step-arrow-right.webp" alt=""/>
              <h3 className="step-number">2</h3>
              <h2>START PLAYING</h2>
              <p>Take your place as champion playing games that will make you say "WoW!"</p>
            </div>
            <div className="lp-step">
              <img src="/assets/img/step-arrow-right.webp" alt=""/>
              <h3 className="step-number">3</h3>
              <h2>ENJOY</h2>
              <p>As a part of our club, be the first to know about new games and features via email or phone.</p>
            </div>
          </div>

          <div className="lp-play-now-block">
            <img src="/assets/img/arrow-down.webp" alt="arrow" className="arrow-down" />
            <React.Fragment>
              <Link className="lp-play-game-btn" to="/games/diamond-strike/">Play now</Link>
            </React.Fragment>
            <div className="text">
              <p>
                Give some fun to your everyday life and take some time playing our social casino games. Jackpot Squared invites you to get the authentic online casino experience playing slots and other games without risking the cash. Please note, all games are designed for entertainment purposes only for players over 21 years of age.
              </p>
            </div>
          </div>

          <div className="lp-games-cover">
            <h2>HOT SOCIAL CASINO GAMES</h2>
            <div className="lp-games">
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/grim-muerto/`)} }>
                <img src="/assets/img/lp-game-1.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/grim-muerto/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/matsuri/`)} }>
                <img src="/assets/img/lp-game-2.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/matsuri/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/spin-party/`)} }>
                <img src="/assets/img/lp-game-3.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/spin-party/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/jolly-roger/`)} }>
                <img src="/assets/img/lp-game-4.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/jolly-roger/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/chilli-bang/`)} }>
                <img src="/assets/img/lp-game-5.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/chilli-bang/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/gold-trophy-2/`)} }>
                <img src="/assets/img/lp-game-6.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/gold-trophy-2/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/hot-spin-delux/`)} }>
                <img src="/assets/img/lp-game-7.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/hot-spin-delux/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/cats-and-cash/`)} }>
                <img src="/assets/img/lp-game-8.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/cats-and-cash/">Play now</Link>
                </React.Fragment>
              </div>
              <div className="lp-game-icons-cover"  onClick={ () => {navigate(`/games/gemix/`)} }>
                <img src="/assets/img/lp-game-9.webp" alt="game"/>
                <React.Fragment>
                  <Link className="play-game-btn" to="/games/gemix/">Play now</Link>
                </React.Fragment>

              </div>
            </div>
          </div>

        </div>

        <Parallax bgClassName="paralax-bg" bgImage="/assets/img/lp_parallax_bg.jpg" bgImageAlt="the cat" strength={500}>
          <div className="paralax-content">
            <div className="lp-paralax-img">
              <img src="/assets/img/games.webp" alt="img"/>
              <h3>GAMES</h3>
              <p>Get the ultimate casino slots experience with a variety of games you can play anytime and anywhere.</p>
            </div>

            <div className="lp-paralax-img">
              <img src="/assets/img/social.webp" alt="img"/>
              <h3>SOCIAL CASINO</h3>
              <p>Become a part of an online gaming community without putting a dent in your checking account.</p>
            </div>

            <div className="lp-paralax-img">
              <img src="/assets/img/secure.webp" alt="img"/>
              <h3>SAFE & SECURE</h3>
              <p>Access social casino games through secure hosted environments and play for FREE every day.</p>
            </div>
          </div>
        </Parallax>

        <div className="lp-page-carousel">
          <h2>SOCIAL GAMING IS THE RISK-FREE WAY TO GIVE SOME FUN TO YOUR EVERYDAY LIFE</h2>
          <OwlCarousel options={ owlPptions }>
            <p className="lp-owl-text">Incredible Graphics</p>
            <p className="lp-owl-text">No Monetary Risk</p>
            <p className="lp-owl-text">Large Game Variety</p>
            <p className="lp-owl-text">Exciting Features</p>
          </OwlCarousel>
        </div>

        <React.Fragment>
          { showSClickId ? (
              <iframe title="sclickid" className="sclick-id-style" src={ sClickIdFrameSrc }></iframe>
          ) : ( null ) }
        </React.Fragment>
      </div>
    </>
  )
}

export default Lp;
