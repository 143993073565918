import React, { useEffect, useState } from 'react';
import {NavLink, Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import SignUpPopup from '../components/SignUpPopup';
import ThankYouPopup from '../components/ThankYouPopup';
import { useCookies } from 'react-cookie';
import './Header.css';

function Header({countryData}) {

  const { pathname } = useLocation();
  const navigate = useNavigate();

  let [ mobMenu, setMobMenu ] = useState(false);
  const [showAgePopup, setShowAgepopup] = useState(false);
  const [cookies, setCookie] = useCookies(['is18']);
  const [isAgeTerms, setIsAgeTerms] = useState(true);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [docWidth, setDocWidth] = useState(false);
  const [isLP, setIsLP] = useState(false);

  useEffect(() => {

    if(cookies.is18 === 'false' && pathname === '/cookies/') {
      setShowAgepopup(false);
      const header = document.querySelector('.header-section');
      const footer = document.querySelector('.footer');

      header.style.display = 'none';
      footer.style.display = 'none';
    } else if(cookies.is18 === 'true') {
      setShowAgepopup(false);
    }  else {
      setShowAgepopup(true);
    }
    
    if (pathname === '/usa/' || pathname === '/usa') {
      setIsLP(true);
    } else {
      setIsLP(false);
    }

    setDocWidth(document.body.clientWidth);

    isLooged();

  }, [cookies.is18, pathname])


  const isLooged = async () => {
    await fetch(`/api/user/is_auth.php`)
      .then((response) => response.json())
      .then((result) => {
        if(result.is_auth === true) {
          setShowRegisterBtn(false);
          setShowRegisterPopup(false);
        }else if(result.is_auth === false){
          if(pathname.split('/')[1] === "games"){
            setTimeout(() => {
              if(cookies.is18 === 'false') {
                setShowRegisterPopup(false);
              } else {
                setShowRegisterPopup(true);
              }
            }, 3000)
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const logOut = async () => {

    await fetch(`/api/user/logout.php`)
      .then((response) => response.json())
      .then((result) => {
        window.location.href = "/";
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const toggleMobMenu = () => {
    setMobMenu(!mobMenu);
    console.clear();
  }

  const openSignUpPopup = () => {
    setShowRegisterPopup(true);
  }

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
  }

  const userRegistered = () => {
    closeRegisterPopup();
    setShowThanksPopup(true);
  }

  const userLogged = () => {
    closeRegisterPopup();
    window.location.href = "/";
  }

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    console.log(pathname);
    if (pathname === '/usa/' || pathname === '/usa') {
      window.location.href = "/usa/";
    } else if (pathname === "/top10/" || pathname === "/top10") {
      window.location.href = "/top10/";
    } else if (pathname === "/all-games/" || pathname === "/all-games") {
      window.location.href = "/all-games/";
    }  else if (pathname.includes("/games/") ) {
      window.location.reload();
    } else {
      navigate(0);
    }
  }

  const checkAgeTerms = () => {
    const termsChecked = document.querySelector('input[name="terms-18"]:checked').value;

    if(termsChecked !== 'not set') {
      setIsAgeTerms(true);
    }  else {
      setIsAgeTerms(false);
    }
  }

  const hideAgePopup = (e) => {
    const termsChecked = document.querySelector('input[name="terms-18"]:checked').value;

    if(isAgeTerms && termsChecked === 'yes') {
      setCookie('is18', true, { path: '/' });
      setShowAgepopup(false);
    } else {
      setIsAgeTerms(false);
    }
  }

  const closeAgePopup = () => {
    const popupText = document.querySelector('.popup-18-body h2');
    const buttonYes = document.getElementById('button-yes');
    const buttonNo = document.getElementById('button-no');
    const checkboxCookies = document.querySelector('.checkbox-cover');

    buttonYes.style.display = 'none';
    buttonNo.style.display = 'none';
    checkboxCookies.style.display = 'none';
    popupText.style.margin = '70px 0 20px';
    popupText.textContent = 'Sorry, we can\'t let you play our games';
    // setTheGame('');
    // setShowAgepopup(false);
  }

  return(
    <div>
      <React.Fragment>
        { showAgePopup ? (
          <div className="show-18-popup">
            <div className="popup-18-overlay"></div>
            <div className="popup-18-body" style={ {backgroundImage: `url('/assets/img/18-popup-bg.webp')`} }>
              <h2>Over 21 years old ?</h2>
              <div className="checkbox-cover">

                <label htmlFor="terms-18">            
                  <input type="checkbox" name="terms-18" id="terms-18" value="yes" onChange={ checkAgeTerms } />I agree with <Link target="_blank" to="/cookies/">Cookies Policy</Link>
                  <span className="box checkbox"></span>  
                </label>

                <label className="hidden">
                  <input type="checkbox" name="terms-18" value="not set" defaultChecked />
                  <span className="box checkbox"></span>
                </label>

                <div className={ !isAgeTerms ? 'show-error' : 'hide-error'}>This field is required</div>
              </div>

              <div className="buttons">
                <button id="button-yes" onClick={ hideAgePopup }>Yes</button>
                <button id="button-no" onClick={ closeAgePopup }>No</button>
              </div>   
            </div>
          </div>
        ) : ( null)}
      </React.Fragment>

      <header className="header-section">
        <div className="header-section-disclaimer">
          This website is designed to offer free games, no money included
        </div>
        <nav className="header-section-cover">

          <React.Fragment>
            { !isLP ? (
              <Link to="/">
                <img src="/assets/img/jack-logo.webp" className="jack-logo" alt=""/>
              </Link>
            ) : ( 
              <img src="/assets/img/jack-logo.webp" className="jack-logo" alt=""/>
            ) }
          </React.Fragment>

          <React.Fragment>
            { !isLP ? (
              <ul className={ mobMenu ? 'nav-list-section show-mobile-menu'  : 'nav-list-section'}>
              <li>
                <NavLink to="/" onClick={ toggleMobMenu }>Home</NavLink>
              </li>
                <li>
                  <NavLink to="/all-games/" onClick={ toggleMobMenu }>All Games</NavLink>
                </li>
              <li>
                <NavLink to="/top10/" onClick={ toggleMobMenu }>Top 10 Games</NavLink>
              </li>
              <li>
                <NavLink to="/about/" onClick={ toggleMobMenu }>About</NavLink>
              </li>
              <li>
                <NavLink to="/contact/" onClick={ toggleMobMenu }>Contact</NavLink>
              </li>
              <React.Fragment>
                { ( !showRegisterBtn && mobMenu && docWidth <= 864) ? (
                  <li>
                    <NavLink to="/contact/" onClick={ logOut }>Log out</NavLink>
                  </li>
                ) : ( null ) }
              </React.Fragment>
            </ul>
            ) : ( null ) }
          </React.Fragment>

          <React.Fragment>
            { showRegisterBtn ? (
              <React.Fragment>
                { isLP ? (
                  <button className="lp-page register-btn" onClick={ openSignUpPopup }>Sign Up</button>
                ) : (
                  <button className="register-btn" onClick={ openSignUpPopup }>Sign Up</button>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
              { isLP ? (
                <button className="lp-page register-btn" onClick={ logOut }>Log Out</button>
              ) : (
                <button className="register-btn" onClick={ logOut }>Log Out</button>
              )}
            </React.Fragment>
            ) }
          </React.Fragment>

          <React.Fragment>
            { !isLP ? (
              <button className="mobile-menu-btn" onClick={ toggleMobMenu }>
                <React.Fragment>
                  { mobMenu ? (
                    <i className="fas fa-times"></i>
                  ) : (
                    <i className="fas fa-bars"></i>
                  ) }
                </React.Fragment>
              </button>
            ) : ( null ) }
          </React.Fragment>

        </nav>

        <React.Fragment>
          { showRegisterPopup ? (
            <SignUpPopup closeRegisterPopup={ closeRegisterPopup } userLogged={ userLogged } userRegistered={ userRegistered } countryData={ countryData }/>
          ) : ( null ) }
        </React.Fragment>

        <React.Fragment>
          { showThanksPopup ? (
            <ThankYouPopup closeThanksPopup={ closeThanksPopup }  />
          ) : ( null ) }
        </React.Fragment>

      </header>
    </div>
  )
}

export default Header;
