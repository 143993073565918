import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

import './Contact.css';

function Contact() {

  useEffect(() => {
    document.title = 'Jackpot Squared - Contact';
  }, []);

  const [ nameError, setNameError ] = useState(true);
  const [ emailError, setEmailError ] = useState(true);
  const [ messageError, setMessageError ] = useState(true);

  const [ nameVal, setNameVal ] = useState();
  const [ emailVal, setEmailVal ] = useState();
  const [ messageVal, setMessageVal ] = useState();

  const [ showForm, setShowForm ] = useState(true);

  const nameRegex = /^[a-zA-Z ]+$/; //eslint-disable-line

  const validateForm = (e) => {
    e.preventDefault();

    checkName();
    checkEmail();
    checkMessage();

    if(!nameError &&
      !emailError &&
      !messageError) {
        
        console.log(nameVal);
        console.log(emailVal);
        console.log(messageVal);

        setShowForm(false);

    }
  }

  const checkName = () => {
    const name = document.getElementById('name').value;
    const errorMsg = document.getElementById('name_error');
    
    if(!nameRegex.test(name) || name === '' || name.length < 2) {
      errorMsg.textContent = `Enter your name`;
      setNameError(true);
      setNameVal('');
    } else {
      setNameError(false);
      setNameVal(name);
    }
  }


  const checkEmail = () => {
    const email = document.getElementById('email-c').value;
    const errorMsg = document.getElementById('email_error');

    const validateEmail = (val) => {
      let re = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; //eslint-disable-line
      return re.test(val);
    }

    if(email === '') {
      errorMsg.textContent = `Enter email`;
      setEmailError(true);
      setEmailVal('');
    } else if (!validateEmail(email)) {
      errorMsg.textContent = `Enter correct email`;
      setEmailError(true);
      setEmailVal('');
    } else {
      setEmailError(false);
      setEmailVal(email);
    }
  }

  const checkMessage = () => {
    const message = document.getElementById('message').value;
    const errorMsg = document.getElementById('message_error');

    if(message.length < 5) {
      errorMsg.textContent = `Enter your message`;
      setMessageError(true);
      setMessageVal('');
    } else {
      setMessageError(false);
      setMessageVal(message);
    }
  }

  return(
    <>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/contact/" />
      </Helmet>
      <div className="text-page-body contact-bg" style={ {backgroundImage: `url('/assets/img/contact-bg.webp')`} }>
        <div className="contact-cover">
          <div className="contact-form-cover">
            <h1 className={ showForm ? '' : 'hide-form' }>Contact</h1>
            <form action="#" method="post" className={ showForm ? '' : 'hide-form' } >

              <label htmlFor="name">
                <input onChange={ checkName } type="text" id="name" placeholder="Name *" />
                <div className={ nameError ? 'error_message-show' : 'error_message-hide'} id="name_error" ></div>
              </label>

              <label htmlFor="email-c">
                <input onChange={ checkEmail } type="email" id="email-c" placeholder="Email *" />
                <div className={ emailError ? 'error_message-show' : 'error_message-hide'} id="email_error" ></div>
              </label>

              <label htmlFor="message">
                <textarea onChange={ checkMessage } name="message" id="message" cols="30" rows="10" placeholder="What you wanna say ? *"></textarea>
                <div className={ messageError ? 'error_message-show' : 'error_message-hide'} id="message_error" ></div>
              </label>

              <button type="submit" onClick={ validateForm }>Send now</button>
            </form>

            <div className={ showForm ? 'hide-success-message' : 'show-success-message' }>Thank you for your message! We will contact you soon</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact;