import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import './About.css';

function About() {

  const [contentHeight, setContentHeight ] = useState();

  useEffect(() => {
    document.title = 'Jackpot Squared - About';
    document.querySelector("meta[name='description']").setAttribute('content',"Welcome to the online Jackpot Squared and take part in a FREE casino game! Our casino features a single atmosphere and extraordinary gameplay.");

    const contentBlock = document.querySelector('.text-page-body');
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return(
    <div className="text-page-body" style={ {backgroundImage: `url('/assets/img/back2-02.webp')`, minHeight: `${ contentHeight }px`} }>
        <Helmet>
            <link rel="canonical" href="https://www.jackpotsquared.com/about/" />
        </Helmet>
      <div className="content-cover">
      <h2>Jackpot Squared Casino - Stupendous Online Casino!</h2>
      <p>Welcome to the online Jackpot Squared allowing you to take part in <strong>a FREE casino game</strong>! Our casino features a single atmosphere and extraordinary gameplay. If that's what you are looking for in times of leisure, you've reached the right spot. With gamers in mind, we have meticulously selected high-quality video slots, including reliable old-school classics, modern video slots with surprising features. Every time we try to find something new for you adding the latest casino games to our game menu.</p>
      <p>Give some fun to your everyday life and take some time playing in our casino. Yes, it is not a real casino. <strong>All the games of Jackpot Squared are absolutely FREE</strong>. That’s why you can be absolutely sure about your pocket. You may ask: why are they doing it? The question is quite simple. We want you to feel the eagerness of hitting a jackpot. Our team is fond of games and loves our players! Thinking of you, we have created an exquisite online casino, where you will find lots of the most interesting games.</p>
      <p>We make every effort to ensure your comfort during your leisure time at Jackpot Squared Casino. Do you like having a choice? Take a seat at the green table and play poker. There are also many variants of blackjack and roulette available.</p>
      <p>Do you have your favorite video slot? Or maybe you like discovering the possibilities of new titles or choosing games that suit your mood and season? You can be sure that Jackpot Squared Casino online slots will meet all your needs. All that's left for you to do is register at the casino and enjoy the exciting gameplay.</p>
      </div>
    </div>
  )
}

export default About;
