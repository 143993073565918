import React, { useEffect, useState } from 'react';
import './Terms.css';
import {Helmet} from "react-helmet";

function Terms() {

  const [contentHeight, setContentHeight ] = useState();

  useEffect(() => {
    document.title = 'Jackpot Squared - Terms & Conditions';

    const contentBlock = document.querySelector('.text-page-body');
    setContentHeight(contentBlock.clientHeight - 400);
  }, [setContentHeight]);

  return(
    <>
      <Helmet>
        <link rel="canonical" href="https://www.jackpotsquared.com/terms/" />
      </Helmet>
      <div className="text-page-body" style={ {backgroundImage: `url('/assets/img/back2-02.jpg')`, minHeight: `${ contentHeight }px`} }>
        <div className="content-cover">
          <h1>Terms & Conditions</h1>

          <h3>1. GENERAL RULES</h3>
          <p>1.1 Please read these Terms and Conditions before using our Jackpot Squared website (hereinafter referred to as the "Website"). Operating with any Website part, you agree to be legally bound by the subsequent terms and conditions (collectively, the "Terms"): </p>
          <ul>
            <li>Privacy policy;</li>
            <li>The specific terms and conditions of advertising campaigns, and special promotions proposed on our Website;</li>
            <li>Rules for certain games provided on our Website;</li>
            <li>Other terms that you may accept to use certain sections and services available on our Website.</li>
          </ul>
          <p>1.2 In the event of any discrepancy, the terms and conditions of advertising campaigns, and special promotions take precedence over the General Regulations.</p>
          <p>1.3 If you do not agree to comply with all the provisions of the Terms, you may not access our Website. By continuing to use the Website, you will accept the Terms.</p>

          <h3>2. LEGAL REQUIREMENTS AND RESTRICTIONS</h3>
          <p>2.1 You may use the Website's services only if you have reached the age of at least 21 y.o. By accepting the Terms and Conditions, you warrant that you comply with the age restrictions above and must leave the Website immediately if you are under 21 y.o.</p>
          <p>2.2 Jackpot Squared does not provide any guarantees on the legality of the use of the Website. It is your responsibility to know your country's laws regarding online casinos. Before opening an account on the Website, you must verify that online casinos are legal in your jurisdiction. By using this Website, you guarantee that you can legally play online. If an online game in your jurisdiction becomes illegal or otherwise restricted at any time, you must immediately close your account with the Website and stop using the Website's services.</p>

          <h3>3. YOUR WARRANTIES</h3>
          <p>3.1 By creating an account on our Site and using our Site, you warrant that:</p>
          <ul>
            <li>you are acting on your behalf.</li>
            <li>you are a competent and law-abiding user.</li>
            <li>you are not addicted to gambling; you do not use the Website under the influence of any substances.</li>
            <li>all data and information you provided when registering on our Website are true.</li>
          </ul>

          <h3>4. EXTRA POINTS AND PROMOTIONS</h3>
          <p>4.1 From time to time, we may offer our customers promotional incentives such as loyalty points, and/or other special offers (referred to in these Terms and Conditions as "extra points" or "promotions"). </p>
          <p>4.2 Each promotion offer is limited to one per person personal or shared computer.</p>
          <p>4.3 We reserve the right to refuse to grant extra points to any customer, refuse any extra points, cancel any promotion, and permanently or temporarily ban any customer or group of customers from the Website's promotion program at our sole and absolute discretion. In the event of any dispute regarding our promotions (including, but not limited to, eligibility, use, abuse, fair play, winnings calculation, and wagering requirements), the Company's decision will be final and non-negotiable.</p>

          <h3>5. LIMITATIONS OF LIABILITY</h3>
          <p>5.1 You accept and agree that the Company has no responsibility or control over how you use the software. You agree that the use of the Company's services is solely your decision, and you agree that you are aware of and accept all possible risks.</p>
          <p>5.2 The Company is not responsible for any contract, illegal act, negligence, damage, or loss of any size, including, but not limited to, loss of data, reputation, and any loss not anticipated at the time by the Company.</p>
          <p>5.3 Neither the Company, nor the software vendor, nor any of our affiliates shall be liable in any way for any losses, expenses, or damages that may or are the consequences of:</p>
          <ul>
            <li>any mistakes you may have made by entering incorrect information;</li>
            <li>any fraud, false or misleading activity;</li>
            <li>the Company's decision not to accept a deposit, refusal to open an account, or a decision to suspend or close the account;</li>
            <li>inability to use or access the Website for any reason;</li>
            <li>any action or transaction carried out by third parties using your username and password.</li>
          </ul>

          <h3>6. CHANGES TO THE SITE</h3>
          <p>6.1 The Company reserves the right to amend the Terms at its sole discretion at any time.</p>
          <p>6.2 Any changes are effective from the date indicated in the Terms. It is your responsibility to check for updates to the Terms each time you use the Website. </p>
          <p>6.3 By continuing to use the Website after any changes are made, you agree to these changes, whether you have received actual notice of the changes or reviewed them yourself. If you do not agree with any of these changes, you must stop using the Website. </p>

        </div>
      </div>
    </>
  )
}

export default Terms;